export interface GuidesType {
  gettingStarted: Array<any>
  topical: Array<any>
  tutorials: Array<any>
}

export const splitGuides = (guides: any): GuidesType => {
  const gettingStarted = guides?.filter(
    (guide: any) => guide.type === "getting-started"
  )
  const topical = guides?.filter((guide: any) => guide.type === "topical")
  const tutorials = guides?.filter((guide: any) => guide.type === "tutorial")

  return {
    gettingStarted,
    topical,
    tutorials,
  }
}

export const getGuides = (
  all: Array<any>,
  paths: Array<string>
): Array<any> => {
  return paths
    .map((path: string) => {
      return all.find(node => node?.path === `/guides${path}`)
    })
    .map(node => ({ ...node }))
}
