import { Link } from "gatsby"
import * as React from "react"

interface Props {
  items: Array<any>
  icon: string
}

const Item = ({ item, icon }: any) => {
  return (
    <div className="column is-half">
      <article className={`has-border-top`}>
        <Link to={item.path}>
          <img style={{ maxWidth: 27 }} src={`/img/icons/${icon}`} />
          <h2>{item.title}</h2>
          <div>{item.description}</div>
        </Link>
      </article>
    </div>
  )
}

const List = ({ items, icon }: Props) => {
  return (
    <div className="columns is-multiline columns-list">
      {items?.map((item: any, index) => (
        <Item icon={icon} key={`item-${index}`} item={item} />
      ))}
    </div>
  )
}

export default List
