import { graphql, Link } from "gatsby"
import * as React from "react"
import List from "../components/guides/List"
import Layout from "../components/layout/Layout"
import { GuidesType, splitGuides } from "../components/utils/Guide"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { DefaultBottom } from "../components/banner/DefaultBottom"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        guideType: string
        keywords?: Array<string>
      }
    }
    guides: {
      nodes: Array<{
        description: string
        name: string
        path: string
        title: string
        type: string
      }>
    }
  }
}

// markup
const GuidesCategoryTemplate = (props: Props) => {
  const guides = splitGuides(props.data?.guides?.nodes)
  const [result, setResult] = React.useState<any>([])

  const guidesByCategory = (guides: GuidesType, category: string) => {
    if (category === "gettingStarted") {
      return guides.gettingStarted
    } else if (category === "topical") {
      return guides.topical
    } else {
      return guides.tutorials
    }
  }

  const getIcon = (category: string) => {
    if (category === "gettingStarted") {
      return "guide.svg"
    } else if (category === "topical") {
      return "topical.svg"
    } else {
      return "tutorial.svg"
    }
  }

  React.useEffect(() => {
    setResult(guidesByCategory(guides, props.data.page.frontmatter.guideType))
  }, [])

  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className="guides guides-category"
    >
      <div className="extra">
        <img src="/img/extra/quickstart.svg" alt="" />
      </div>
      <div className="container mt-6 mb-6">
        <div className="mb-6">
          <Link to={"/guides"} className="button is-white is-white-spring">
            <FontAwesomeIcon className="icon" icon={faAngleLeft} />
            All guides
          </Link>
        </div>
        <div className="pt-3 pb-3">
          <h2 id="gettingStarted" className="is-size-3 has-text-weight-medium">
            {props.data.page.frontmatter.title}
          </h2>
          <div className="mt-2 mb-2">
            {props.data.page.frontmatter.description}
          </div>
        </div>
        <div className="pt-3 pb-3">
          <List
            icon={getIcon(props.data.page.frontmatter.guideType)}
            items={result}
          />
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default GuidesCategoryTemplate

// graphql queries
export const pageQuery = graphql`
  query GuidesTemplate($id: String!) {
    guides: allApiGuide {
      nodes {
        description
        name
        title
        type
        path
      }
    }
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        guideType
      }
    }
  }
`
